import type { ICategory } from "@magnit/unit-catalog/src/unit-catalog.types";
import { GOODS_ROOT_KEY } from "~/constants/categories";
import { useStoresStore } from "~/store/stores";

export const useCategoriesStore = defineStore("categories", () => {
  const { fetchGoodsCategories: apiFetchGoodsCategories, requestGoodsCategories: apiRequestGoodsCategories } = categoriesApi();
  const storesStore = useStoresStore();
  const { storeShopCode } = storeToRefs(storesStore);

  const goodsRoot = computed<ICategory>(() => ({
    id: "",
    key: GOODS_ROOT_KEY,
    name: "Все категории",
    code: "root",
    url: `${Routes.Catalog}?shopCode=${storeShopCode.value}`,
    parentKey: null,
    children: [],
  }));

  const goods = ref<ICategory[]>([]);
  const structure = computed<ICategory[]>(() => [...goods.value]);
  const db = computed<Record<ICategory["key"], ICategory>>(() => {
    const result: Record<ICategory["key"], ICategory> = {};
    function inlineCategories(categories: ICategory[]) {
      categories.forEach((i) => {
        result[i.key] = i;
        if (i.children.length) {
          inlineCategories(i.children);
        }
      });
    }

    result[goodsRoot.value.key] = { ...goodsRoot.value, children: goods.value };
    inlineCategories(goods.value);

    return result;
  });

  const requestGoodsCategories = async (isDirectFetch = false) => {
    let data = null;
    if (isDirectFetch) {
      data = await apiFetchGoodsCategories();
    } else {
      data = await apiRequestGoodsCategories();
    }
    goods.value = toValue(data) || [];
  };

  const checkGoodsCategories = async (force = false, isDirectFetch = false) => {
    if (goods.value.length && !force) {
      return;
    }
    await requestGoodsCategories(isDirectFetch);
  };

  const requestCategories = async (force = false, isDirectFetch = false) => {
    await checkGoodsCategories(force, isDirectFetch);
  };

  const getKeyById = (id: ICategory["id"], type: "promo" | "goods"): string => {
    if (!id) {
      id = "0000";
    }
    return `${type === "promo" ? "p" : "g"}${id}`;
  };

  const getCategoryByKey = (key: ICategory["key"]): ICategory | null => {
    return db.value[key] || null;
  };

  const getCategoryById = (
    id: ICategory["id"],
    type: "promo" | "goods",
  ): ICategory | null => {
    const key = getKeyById(id, type);
    return getCategoryByKey(key);
  };

  const getParents = (key: ICategory["key"]): ICategory[] => {
    let result: ICategory[] = [];

    const current = getCategoryByKey(key);

    if (current) {
      result = [current];

      if (current.parentKey) {
        const parents = getParents(current.parentKey);

        if (parents.length) {
          result = [...parents, ...result];
        }
      }
    }

    return result;
  };

  const getParentsByKey = (key: ICategory["key"]): ICategory[] => {
    const current = getCategoryByKey(key);
    if (!current || !current.parentKey) {
      return [];
    }

    const parent = getCategoryByKey(current.parentKey);

    if (!parent) {
      return [];
    }

    return getParents(parent.key);
  };

  const getParentsById = (
    id: ICategory["id"],
    type: "promo" | "goods",
  ): ICategory[] => {
    const key = getKeyById(id, type);

    return getParentsByKey(key);
  };

  const getChildrenByKey = (key: ICategory["key"]): ICategory[] => {
    const current = getCategoryByKey(key);
    let result: ICategory[] = [];

    if (current) {
      result = [current];

      if (current.children) {
        const childs = current.children.reduce<ICategory[]>((res, child) => {
          const next = getChildrenByKey(child.key);
          return [...res, ...next];
        }, []);

        if (childs.length) {
          result = [...result, ...childs];
        }
      }
    }

    return result;
  };

  const getChildrenById = (
    id: ICategory["id"],
    type: "promo" | "goods",
  ): ICategory[] => {
    const key = getKeyById(id, type);

    return getChildrenByKey(key);
  };

  const getTypeByKey = (key: ICategory["key"]): "promo" | "goods" => {
    return key[0] === "p" ? "promo" : "goods";
  };

  const getTreeNameByKey = (key: ICategory["key"]): string => {
    const current = getCategoryByKey(key);
    if (!current) {
      return "";
    }
    const arrayCategoryNames = getParentsByKey(current.key).map((i) => i.name);
    arrayCategoryNames.push(current.name);

    return arrayCategoryNames.join("/");
  };
  const getCategoryDescendants = (category: ICategory["id"][]) => {
    const tree = getChildrenById(category[0] || "", "goods");
    return [...tree.map((b) => Number(b.id))];
  };
  const getCategoryAscendingTree = (category: ICategory["id"][]) => {
    const current = getCategoryById(category[0], "goods");
    const tree = getParentsById(category[0] || "", "goods");
    if (current) tree.push(current);
    return [...tree.map((b) => ({ to: b.url, title: b.name }))];
  };

  return {
    db,
    structure,
    goods,
    promo: [], // эх пришлось всё равно сделать заглушку, чтобы подходить под тип ICategoriesFunctions
    getCategoryDescendants,
    getCategoryAscendingTree,
    requestCategories,
    getCategoryByKey,
    getCategoryById,
    getParentsByKey,
    getParentsById,
    getChildrenByKey,
    getChildrenById,
    getTypeByKey,
    getTreeNameByKey,
  };
});
