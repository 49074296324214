import type { ICategory } from "@magnit/unit-catalog/src/unit-catalog.types";
import { captureException } from "@sentry/nuxt";
import { urls } from "~/api/config";
import type { IGoodCategoryDataResponse, IGoodCategoryResponse } from "~/typings/api";
import { API_SHOP_TYPE } from "~/constants/api";

export default () => {
  const storesStore = useStoresStore();
  const { storeShopCode } = storeToRefs(storesStore);

  const formatGoodsCategories = (
    category: IGoodCategoryResponse,
    parentKey: ICategory["parentKey"] = null,
  ): ICategory => {
    const key = `g${category.id}`;
    return {
      key,
      parentKey,
      id: String(category.id),
      name: category.name,
      code: category.seoCode,
      icon:
        category.image && parentKey === GOODS_ROOT_KEY
          ? category.image
          : undefined,
      url: `${Routes.Catalog}/${category.id}-${category.seoCode}?shopCode=${storeShopCode.value}&shopType=${API_SHOP_TYPE}`,
      children: category.children.map((subcategory) =>
        formatGoodsCategories(subcategory, key),
      ),
    };
  };

  const transformGoodsCategories = (data: IGoodCategoryDataResponse): ICategory[] =>
    (data?.items || [])
      .filter((category) => !GOODS_IGNORE_IDS.includes(category.id))
      .map((category) => formatGoodsCategories(category, GOODS_ROOT_KEY));

  const requestGoodsCategories = async (): Promise<Ref<ICategory[] | null>> => {
    const { data } = await useTransport<IGoodCategoryDataResponse, ICategory[]>(
      `${urls.goods.categories}/${storeShopCode.value}`,
      {
        lazy: import.meta.client,
        query: {
          storetype: API_SHOP_TYPE,
          catalogtype: API_CATALOG_TYPE,
        },
        transform: transformGoodsCategories,
      },
    );
    return data;
  };

  const fetchGoodsCategories = async (): Promise<ICategory[] | null> => {
    try {
      const data = await useDirectFetch<IGoodCategoryDataResponse>(
        `${urls.goods.categories}/${storeShopCode.value}`,
        {
          query: {
            storetype: API_SHOP_TYPE,
            catalogtype: API_CATALOG_TYPE,
          },
        },
      );
      return transformGoodsCategories(data);
    } catch (e) {
      captureException(e);
      console.error(e);
      return null;
    }
  };

  return {
    requestGoodsCategories,
    fetchGoodsCategories,
  };
};
